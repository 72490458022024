<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{ clusterAdmin.full_name }}</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <router-link :to="{name: 'cluster-admin-edit',params: {'id': clusterAdmin.id}}" class="header-icon header-icon-2"
        v-if="hasPermission('cluster_admin_update')">
        <i class="far fa-edit"></i>
    </router-link>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">


    <!------------- CLUSTER ADMIN DETAIL PART START ------------->

    <div class="card card-style mx-4 mt-3" v-if="clusterAdmin">
        <div class="content">
            <div class="ca-details mb-3 mx-2 " v-if="clusterAdmin.email">
                <div class="ca-detailIconContainer me-3">
                    <div class="ca-detailIconWrapper">
                        <i class="fas fa-envelope fa-x ca-detailIcon"></i>
                    </div>
                </div>
                <div class="ca-detailInfo">
                    <h5 class="font-600">{{ clusterAdmin.email.length > 25 ? `${clusterAdmin.email.slice(0,25)}...` : clusterAdmin.email}}</h5>
                </div>
            </div>
            <div class="ca-details mb-3 mx-2">
                <div class="ca-detailIconContainer me-3">
                    <div class="ca-detailIconWrapper">
                        <i class="fas fa-phone-alt fa-x ca-detailIcon"></i>
                    </div>
                </div>
                <div class="ca-detailInfo">
                    <h5 class="font-600">{{ clusterAdmin.phone ? clusterAdmin.phone : ''}}</h5>
                </div>
            </div>
            <div class="ca-details mb-3 mx-2">
                <div class="ca-detailIconContainer me-3">
                    <div class="ca-detailIconWrapper">
                        <i class="fas fa-globe-americas fa-x ca-detailIcon"></i>
                    </div>
                </div>
                <div class="ca-detailInfo">
                    <h5 class="font-600">{{clusterAdmin.country ? clusterAdmin.country.emoji : ""}} &nbsp;
                        {{clusterAdmin.country ? clusterAdmin.country.name : ""}}</h5>
                </div>
            </div>
            <div class="ca-details mx-2">
                <div class="ca-detailIconContainer me-3">
                    <div class="ca-detailIconWrapper">
                        <i class="fas fa-clock fa-x ca-detailIcon"></i>
                    </div>
                </div>
                <div class="ca-detailInfo">
                    <h5 class="font-600">{{clusterAdmin.created_at ? localDateTime(clusterAdmin.created_at) : ''}}
                    </h5>
                </div>
            </div>
        </div>
    </div>

    <!------------- CLUSTER ADMIN DETAIL PART START ------------->

    <!------------- CLUSTER ADMIN CONNECTED REGION PART START ------------->

    <div class="ca-cardTitleContainer mb-3 mx-4">
        <div class="ca-validationIconWrapper me-3">
            <div class="ca-IconContainer">
                <i class="fas fa-link ca-cardIcon"></i>
            </div>
        </div>
        <h5 class="ca-cardTitle mb-0">
            Connected Regions
        </h5>
    </div>

    <div class="card card-style mx-4" v-if="clusterAdmin">
        <div class="content">
            <div class="ca-details mb-3 mx-2" v-if="clusterAdmin.state">
                <div class="ca-locationDetailIconContainer me-3">
                    <div class="ca-locationDetailIconWrapper">
                        <i class="fas fa-satellite fa-x ca-locationDetailIcon"></i>
                    </div>
                </div>
                <div class="ca-detailInfo">
                    <h5>{{ clusterAdmin.regions_name.state ? clusterAdmin.regions_name.state : '-' }}
                    </h5>
                </div>
            </div>
            <div class="ca-details mb-3 mx-2" v-if="clusterAdmin.city">
                <div class="ca-locationDetailIconContainer me-3">
                    <div class="ca-locationDetailIconWrapper">
                        <i class="fas fa-city fa-x ca-locationDetailIcon"></i>
                    </div>
                </div>
                <div class="ca-detailInfo">
                    <h5>{{ clusterAdmin.regions_name.city ? clusterAdmin.regions_name.city : '-' }}
                    </h5>
                </div>
            </div>
            <div class="ca-details mx-2" v-if="clusterAdmin.zone">
                <div class="ca-locationDetailIconContainer me-3">
                    <div class="ca-locationDetailIconWrapper">
                        <i class="fas fa-map-marker-alt fa-x ca-locationDetailIcon"></i>
                    </div>
                </div>
                <div class="ca-detailInfo">
                    <h5>{{ clusterAdmin.regions_name.zone ? clusterAdmin.regions_name.zone : '-' }}
                    </h5>
                </div>
            </div>
            <!-- <div class="row mb-2" v-if="clusterAdmin.state">
                <div class="col-5">States</div>
                <div class="col-7 color-theme">
                    {{ clusterAdmin.regions_name.state ? clusterAdmin.regions_name.state : '-' }}
                </div>
            </div>
            <div class="row mb-2" v-if="clusterAdmin.city">
                <div class="col-5">Cities</div>
                <div class="col-7 color-theme">
                    {{ clusterAdmin.regions_name.city ? clusterAdmin.regions_name.city : '-' }}
                </div>
            </div>
            <div class="row mb-2" v-if="clusterAdmin.zone">
                <div class="col-5">Zones</div>
                <div class="col-7 color-theme">
                    {{ clusterAdmin.regions_name.zone ? clusterAdmin.regions_name.zone : '-' }}
                </div>
            </div> -->
        </div>
    </div>

    <!------------- CLUSTER ADMIN CONNECTED REGION PART END ------------->

</div>